.redes-container {
  height: 100%;
  background-color: #e9e4dc;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
}

.redes-page-container {
  height: 82.4vh;
}

.redes-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
  letter-spacing: 10px;
  font-family: "Yeserva One";
  margin-right: 20px;
}

.title-redes {
  font-family: "Quicksand";
}

.subTitle-redes {
  font-family: "Shifunk Light";
  font-size: medium;
  font-weight: 5;
}

.redes1-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 20px;
  width: 95%;
}

.redes1-icon-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.redes1-icon-container {
  display: flex;
  flex-direction: row;
}

.icon-title-container,
.title-icon-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

h1,
h4 {
  margin: 3px 0;
}

.redes-image {
  margin: 0 10px !important;
  border: 5px solid black;
  border-radius: 55px;
}
.redes-icon {
  margin-right: 5px;
}

.redes2-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 70%;
  margin: 0 20px;
}

.redes2-icon-title-container {
  display: flex;
  text-align: right;
  align-items: center;
  padding: 20px 20px;
}

.redes2-icon-title {
  margin-right: 5px;
}

.redes2-image {
  margin: 0 10px !important;

  border: 5px solid black;
  border-radius: 55px;
}

.redes2-icon {
  margin-left: 5px;
}

@media screen and (max-width: 1080px) {
  .redes-container {
    display: flex;
    flex-direction: column-reverse;
    height: 750px;
    width: 100%;
    padding: 50px 0;
    margin: 0;
    align-items: flex-start;
    padding-bottom: 150px;
    /*    margin-bottom: 5%; */
  }

  .redes1-container {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .redes1-icon-title-container {
    padding: 10px;
  }

  .redes2-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
  }
  .redes2-icon-title-container {
    padding: 10px 0;
    flex-direction: row-reverse;
    text-align: left;
    justify-content: flex-end;
  }

  .redes-image,
  .redes2-image {
    width: 150px;
    height: 150px;
    border-radius: 70px;
    border: 0px;
  }

  .redes-title {
    font-size: 70px;
    margin-right: 0;
    width: 100%;
    text-align: center;
  }

  .icon-title-container,
  .title-icon-container {
    font-size: 10px;
  }

  .title-icon-container {
    flex-direction: row-reverse;
  }

  .redes2-icon {
    margin-left: 0;
    margin-right: 5px;
  }
}

@media (min-width: 1080px) and (max-width: 1200px) {
  .redes-container {
    display: flex;
    flex-direction: column-reverse;
    height: 700px;
  }
}
