.header--icon {
  height: 50px !important;
  margin-left: 10%;
  margin-top: 10%;
  display: flex;
  justify-content: flex-start;
}

.header--container {
  display: flex;
  height: 70px;
  align-items: center;
  /*   margin: 20px; */
  justify-content: flex-start !important;
  position: fixed;
  width: 100%;
  background: white;
  z-index: 10;
  top: 0;
  padding-bottom: 10px;
}
