.cursos--container {
  display: flex;
  align-items: center;
  height: 750px;
  width: 100%;
  background-color: #e0cfb4;
  align-items: center;
  justify-content: center;
}

.cursos--title {
  font-weight: 600;
  font-size: 90px;
  font-family: Lovelo;
  width: 75%;
}

.cursos-description {
  font-family: "Cardo" !important;
  line-height: 2;
  font-size: larger;
  color: white;
  width: 82%;
}

.cursos-title-description-container {
  width: 80% !important;
  background-image: url(../../../images/cursoPortada.png);
  background-size: cover;
  height: 650px;
  z-index: 1;
  object-fit: fill;
  display: flex;
  justify-content: space-between;
}

.cursos-title-subDescription-button-container {
  color: black;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  margin: 0 3%;
}

.cursos-subDescription {
  margin: 0;
  font-size: 30px;
}

.cursos-description-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 26%;
  align-items: center;
  text-align: right;
  padding-right: 1%;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 1;
}
.animate_fadeInDown {
  --animate-duration: 7s;
  --animation-delay: 7s;
  width: 100% !important;
}

.cursos-button-container {
  display: flex;
  align-items: center;
}

.cursos-button {
  background-color: white !important;
  color: black !important;
  font-family: "Cormorant Garamond Bol" !important;
  font-size: x-large !important;
  border-radius: 20px !important;
  letter-spacing: 8px !important;
  margin-bottom: 40px !important;
  align-items: center !important;
}

.cursos-subTitle-container {
  width: 10%;
}

.cursos-subTitle {
  writing-mode: vertical-rl;
  font-size: 180px;
  font-weight: bold;
  margin-left: -60%;
  font-family: "Yeserva One";
}

/* @media screen and (min-width: 960px) {
  .cursos-title-description-container {
    background-repeat: round;
  }
} */
/* posible 800px, pero tengo que arreglar el titulo */

@media screen and (max-width: 1130px) {
  .cursos-subTitle {
    font-size: 160px;
  }
  .cursos-description-button {
    width: 46%;
  }

  /*   .cursos--container {
    margin: 0;
    width: 100%;
    height: 820px;
    display: block;
  }

  .cursos--title {
    margin-top: 20px;
    font-size: 20px;
    width: auto;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    width: 90%;
  }



  .cursos-description-button {
    display: contents;
    width: 100%;
    height: 100%;
  }

  .cursos-description {
    font-size: medium;
    line-height: 1.5;
    text-align: center;
    width: 100%;
    position: relative;
    top: 45%;
  }

  .cursos-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 44%;
  }

  .cursos-button {
    font-size: medium !important;
    background-color: black !important;
    color: white !important;
    width: 100%;
    text-align: center !important;
  }

  .cursos-title-subDescription-button-container {
    margin: 0;
    text-align: right;
  }

  .cursos-subDescription {
    margin: 0 20px;
    width: 91%;
  } */
}

/* @media (min-width: 720px) and (max-width: 1020px) {
  .cursos--container {
    margin: 0;
    width: 100%;
    height: 800px;
    display: flex;
    flex-direction: column-reverse;
  }

  .cursos-subTitle {
    writing-mode: unset;
    font-size: 150px;
    text-align: center;
    margin-left: 0;
  }

  .cursos-subTitle-container {
    width: 100%;
  }
} */

@media screen and (max-width: 786px) {
  .cursos--container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 5%;
  }

  .cursos--title {
    font-size: 50px;
  }

  .cursos-subTitle {
    writing-mode: unset;
    font-size: 85px;
    margin-left: 0;
    margin-bottom: -9%;

    margin-top: 0;
    padding-top: 30px;
  }
  .cursos-subTitle-container {
    text-align: center;
    width: auto;
  }

  .cursos-subDescription {
    font-size: 20px;
  }

  .cursos-title-description-container {
    width: 90% !important;
    display: flex;
    flex-direction: column;
    background-image: url(../../../images/cursosPortadaPhone.JPG);
    height: 74vh;
    height: 100%;
  }
  .cursos-description-button {
    width: 99%;
    margin-top: 86%;
  }

  .cursos-description {
    font-size: 15px;
    text-align: right;
  }

  .cursos-button-container {
    margin: 5% 0;
    justify-content: center;
  }
  .cursosPortada-button {
    margin: 0 !important;
    font-size: 20px !important;
  }
}
