.contact-container {
  display: flex;
  width: 100%;
}

.image-container {
  width: 50%;
  height: 750px;
}

.image-container img {
  margin: 0;
}

.contact-form-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 50%;
  background-color: #e6dac6;
}

.contact-form-container h1 {
  font-family: "Black Mango";
  font-size: 70px;
  margin-bottom: 0;
}

.contact-form-container p {
  font-family: "RoxboroughCF";
  font-size: 25px;
  margin-top: 10px;
}

.contact-input {
  width: 90%;
  height: 30px;
  border: transparent;
  padding: 10px;
  margin: 5px;
}

.contact-input::placeholder {
  padding-left: 5px;
}

@media screen and (max-width: 720px) {
  .contact-container {
    display: flex;
    flex-direction: column-reverse;
    height: 800px;
    justify-content: flex-end;
    /* margin-bottom: 37%; */
  }

  .image-container {
    width: 100%;
    height: 450px;
  }
  .image-container img {
    height: 100%;
  }

  .contact-form-container {
    width: 100%;
    height: 350px;
  }

  .contact-form-container h1 {
    font-size: 40px;
  }

  .contact-form-container p {
    font-size: 20px;
  }

  /*   .contact-input {
    width: 300px;
  } */
}
