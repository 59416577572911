a {
  text-decoration: none;
  color: inherit;
}

.servicios--container {
  width: 100%;
  align-items: flex-end;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: white !important;
  margin: 3% 0;
  height: 95vh;
  /*   height: 700px;
 */
  text-decoration: none;
}

.servicios--title {
  text-align: center;
  position: absolute;
  font-size: 150px;
  font-family: "Lovelo";
  align-items: center;
  margin-top: 240px;
}

.serviciosCard--subtitle {
  position: absolute;
  width: 33%;
  z-index: 2;
}

.servicios--component {
  display: flex;
  width: 100%;
  align-items: flex-start;
  height: 75vh;
}

.servicioCard--container {
  width: 35%;
  height: 75vh;
  /*   height: 700px;
 */
}

.servicioCard--container:hover
  .servicioCard--image:not(.serviciosCard--subtitle):not(.servicioCard-number) {
  filter: grayscale(100%);
  transition: all 0.5s ease;
}

.servicioCard--image {
  width: 100%;
  /*  height: 75vh; */
}

.servicioCard-number {
  z-index: 3 !important;
  margin: -100px 0;
  font-size: 70px;
  position: relative;
}

.servicios--title {
  z-index: 2;
}

@media screen and (max-width: 1190px) {
  .servicioCard--container,
  .servicios--container {
    /*     height: 600px;
 */
    margin-bottom: 0;
  }
  .servicios--title {
    margin-top: 180px;
  }

  .servicios-page-container {
    height: 85vh;
  }
}

@media screen and (max-width: 1020px) {
  .servicios--title {
    font-size: 50px;
    margin-top: 18% !important;
  }

  .servicios--container {
    height: 450px;
  }

  .servicios-page-container {
    height: 86vh;
  }

  .servicioCard--container {
    height: 200px !important;
  }

  .serviciosCard--subtitle {
    font-size: 15px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .servicioCard-number {
    font-size: 25px;
    position: relative;
    top: 65px;
  }
}

@media screen and (max-width: 720px) {
  .servicios--container {
    /*     
 */ /*  margin: 9% 0; */
    margin-top: 9%;
  }

  .servicios-page-container {
    height: 83.5vh;
  }
}

@media screen and (max-width: 480px) {
  .servicios--container {
    height: 100%;
    margin-top: 9%;
    /*     margin: 9% 0;
 */
  }

  .servicios-page-container {
    height: 80vh;
  }
  .servicios--component {
    height: 100%;
  }
}
