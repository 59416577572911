.presetsInformation-container {
  background-color: #e9e4dc;
  padding-top: 40px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 20px;
}

.presetsInformation-title-description {
  display: flex !important;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.presetsIformation-title {
  font-size: 100px;
  color: white;
  letter-spacing: 15px;
  font-family: "Brice RegularSemiEx";
}

.presetsIformation-description {
  width: 63%;
  font-size: 25px;
  line-height: 1.5;
  font-family: "Glacial Indifference";
  margin: 0;
}

.preset-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
}

.preset-grid {
  padding: 20px;
  text-align: center;
}

.presetsInformation-button {
  margin-top: 15px !important;
  font-size: larger !important;
  background-color: black !important;
  color: white !important;
  width: 30%;
  text-align: center !important;
  border-radius: 25px !important;
  font-family: "Cardo" !important;
  letter-spacing: 5px !important;
}

@media screen and (max-width: 1020px) {
  .presetsIformation-title {
    font-size: 60px;
    text-align: center;
    letter-spacing: 10px;
  }

  .presetsIformation-description {
    width: 90%;
  }

  .presetsInformation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .preset-grid-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50%;
  }

  .presetsInformation-image {
    width: 100% !important;
    height: 100% !important;
  }

  .presetsInformation-button {
    width: 50%;
  }
}
